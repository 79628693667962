import supabase from "@/libs/supabase"

export default {
    namespaced: true,
    state: {
        user_session: null,
        user_authenticated: false,
        user_details: {
            user_firstname: "",
            user_lastname: "",
            user_email: "",
            user_profile_image: null,
            user_type_name: "Admin",
        },
    },
    getters: {
        getUserDetails: state => state.user_details,
        getUserSession: state => state.user_session,
        getUserAuthenticated: state => state.user_authenticated,
    },
    mutations: {
        SET_USER_SESSION(state, val) {
            state.user_session = val
        },
        SET_USER_AUTHENTICATED(state, val) {
            state.user_authenticated = val
        },
        SET_USER_DETAILS(state, val) {
            state.user_details = val
        },
    },
    actions: {
        setUserDetails: async ({ state, commit, rootState }, payload) => {
            if (state.user_session) {
                const data = await supabase
                    .from("user_details")
                    .select("*")
                    .match({ user_uid: state.user_session.user.id });
                const user = data.data[0];
                await commit('SET_USER_DETAILS', {
                    user_firstname: user ? user.user_firstname : null,
                    user_lastname: user ? user.user_lastname : null,
                    user_email: state.user_session.user.email,
                    user_profile_image: user ? user.user_profile_image : null,
                    user_type_name: "Admin",
                });
            }
        },
    },
}