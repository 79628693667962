import { createClient } from "@supabase/supabase-js";

const options = {
    schema: 'public',
    // headers: { 'x-my-custom-header': 'my-app-name' },
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true
};

const supabase = createClient(
    process.env.VUE_APP_SUPABASE_URL,
    process.env.VUE_APP_SUPABASE_KEY,
    options
);

export default supabase;