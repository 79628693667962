import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/pages/Authentication/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'Registration',
      component: () => import('@/views/pages/Authentication/Registration.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgotpassword',
      name: 'ForgotPassword',
      component: () => import('@/views/pages/Authentication/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/resetpassword',
      name: 'ResetPassword',
      component: () => import('@/views/pages/Authentication/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/user',
      name: 'user',
      component: () => import('@/views/pages/User/UserList.vue'),
      meta: {
        pageTitle: 'UserList',
        breadcrumb: [
          {
            text: 'User',
            active: true,
          },
        ],
      },
    },
    {
      path: '/client',
      name: 'client',
      component: () => import('@/views/pages/Client/Client.vue'),
      meta: {
        pageTitle: 'Client',
        breadcrumb: [
          {
            text: 'Client',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pdf',
      name: 'pdf',
      component: () => import('@/views/pages/Pdf/Pdf.vue'),
      meta: {
        pageTitle: 'Pdf',
        breadcrumb: [
          {
            text: 'Pdf',
            active: true,
          },
        ],
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/pages/Settings/Settings.vue'),
      meta: {
        pageTitle: 'Settings',
        breadcrumb: [
          {
            text: 'Settings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router;
