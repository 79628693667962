import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import supabase from './libs/supabase';
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')
require('@core/assets/fonts/feather/iconfont.css')
require('@core/assets/fonts/feather/common.css')



// import assets styles
require('@/assets/scss/style.scss')



Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

supabase.auth.onAuthStateChange((event, session) => {

  if (["PASSWORD_RECOVERY"].includes(event)) {
    router.replace('/resetpassword')
  }
  store.commit("user/SET_USER_SESSION", session);
  store.dispatch("user/setUserDetails");
});
